
















import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

@Component({
  components: {
    Order: () => import("@/app/modules/orders/components/OrderComponent.vue")
  }
})
export default class OrdersPage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView("Orders");
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get ordersData() {
    return this.$store.getters["orders/data"];
  }

  created() {
    this.$store.dispatch("orders/fetchAll").then(({ data }) => {
      this.$store.commit("orders/setData", data.data);
    });
  }
}
